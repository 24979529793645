import React from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import CEIS101 from "../images/CEIS101-Final.png";
import CEIS106 from "../images/CEIS106-Final.png";
import CEIS110 from "../images/CEIS110-Final.png";
import CEIS114 from "../images/CEIS114-Final.png";
import CEIS150 from "../images/CEIS150-Final.png";
import NETW191 from "../images/NETW191-Final.png";
import SEC285 from "../images/SEC285-Final.png";
import CEIS101PPTX from "../downloads/CEIS101.pptx";
import CEIS106PPTX from "../downloads/CEIS106.pptx";
import CEIS110PPTX from "../downloads/CEIS110.pptx";
import CEIS114PPTX from "../downloads/CEIS114.pptx";
import CEIS150PPTX from "../downloads/CEIS150.pptx";
import NETW191PPTX from "../downloads/NETW191.pptx";
import SEC285PPTX from "../downloads/SEC285.pptx";

const Projects = () => {
  return (
    <div className="ProjectsContainer" id="projects">
      <div className="ProjectsTitle">
        <h4>TECH CORE PROJECTS</h4>
        <p>DeVry University</p>
      </div>
      <div className="ProjectsGrid">
        <div className="ProjectWrapper">
          <div className="ProjectImage">
            <img src={CEIS114} alt="CEIS114" />
          </div>
          <div className="ProjectTitle">CEIS114 Final Project</div>
          <div className="ProjectLink">
            <a href={CEIS114PPTX} download>
              Download Powerpoint <MdOutlineFileDownload />
            </a>
          </div>
        </div>
        <div className="ProjectWrapper">
          <div className="ProjectImage">
            <img src={CEIS150} alt="CEIS150" />
          </div>
          <div className="ProjectTitle">CEIS150 Final Project</div>
          <div className="ProjectLink">
            <a href={CEIS150PPTX} download>
              Download Powerpoint <MdOutlineFileDownload />
            </a>
          </div>
        </div>
        <div className="ProjectWrapper">
          <div className="ProjectImage">
            <img src={SEC285} alt="SEC285" />
          </div>
          <div className="ProjectTitle">SEC285 Final Project</div>
          <div className="ProjectLink">
            <a href={SEC285PPTX} download>
              Download Powerpoint <MdOutlineFileDownload />
            </a>
          </div>
        </div>
        <div className="ProjectWrapper">
          <div className="ProjectImage">
            <img src={CEIS101} alt="CEIS101" />
          </div>
          <div className="ProjectTitle">CEIS101 Final Project</div>
          <div className="ProjectLink">
            <a href={CEIS101PPTX} download>
              Download Powerpoint <MdOutlineFileDownload />
            </a>
          </div>
        </div>
        <div className="ProjectWrapper">
          <div className="ProjectImage">
            <img src={CEIS106} alt="CEIS106" />
          </div>
          <div className="ProjectTitle">CEIS106 Final Project</div>
          <div className="ProjectLink">
            <a href={CEIS106PPTX} download>
              Download Powerpoint <MdOutlineFileDownload />
            </a>
          </div>
        </div>
        <div className="ProjectWrapper">
          <div className="ProjectImage">
            <img src={CEIS110} alt="CEIS110" />
          </div>
          <div className="ProjectTitle">CEIS110 Final Project</div>
          <div className="ProjectLink">
            <a href={CEIS110PPTX} download>
              Download Powerpoint <MdOutlineFileDownload />
            </a>
          </div>
        </div>
        <div className="ProjectWrapper">
          <div className="ProjectImage">
            <img src={NETW191} alt="NETW191" />
          </div>
          <div className="ProjectTitle">NETW191 Final Project</div>
          <div className="ProjectLink">
            <a href={NETW191PPTX} download>
              Download Powerpoint <MdOutlineFileDownload />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
