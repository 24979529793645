import React from "react";

const About = () => {
  return (
    <div className="AboutContainer" id="about">
      <div className="AboutHero">
        <div className="LeftSide">
          <h2>Hello! I'm Amberlyn!</h2>
          <p>
            I am a dedicated and hardworking US Army Veteran that found my
            passion in Software Development. I pride myself on my extreme
            attention to detail and love to experiment with code. I graduated
            from Bottega University with a certificate in Full Stack Development
            and currently working on my Bachelor's degree from DeVry University.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
